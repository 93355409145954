import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxFlpmQ7k0Twd5O3yPlMmL8wpogcszhUc",
  authDomain: "adobe-url-builder.firebaseapp.com",
  projectId: "adobe-url-builder",
  storageBucket: "adobe-url-builder.appspot.com",
  messagingSenderId: "141585718730",
  appId: "1:141585718730:web:03c4df2eabc7e757a8c333",
  measurementId: "G-4WX71HFEYD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };